<template>
  <div>
    <el-button type="primary" @click="openDialog">打开弹出层</el-button>
    <el-dialog
      v-model="dialogVisible"
      title="提示"
      width="30%"
      @close="handleClose"
    >
      <span>这是一段内容</span>
      <template #footer>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true
    },
    handleClose() {
      console.log('弹出层关闭了')
    }
  }
}
</script>

<style scoped>
  /* 可以在这里添加样式 */
</style>
